<template>
  <div class="page-item-wrap">
    <main class="sub __login">
      <div class="inner __join-wrap">
        <div class="tit req-type">
          <h1>속초/여수 한 달 살기</h1>
          <p class="stit">속초/여수 한 달 살기 자세히 보기</p>
        </div>
        <div class="sub-cont">
          <div class="alert-msg">
            <img
              src="~assets/image/main/Jeju2_Detail.png"
              alt="팝업
            이미지"
              id="mapsPopup"
              style="width: 100%"
            />
            <!-- <main-job-swiper :list="bannerList"></main-job-swiper> -->
          </div>
          <button class="btn __search" @click="routePush('JejuAllTheTime')">
            신청하기
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { saveReadCnt } from 'Api/modules'
export default {
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      isJeju: false,
      eventSeq: 2,
    }
  },
  watch: {
    isJeju() {
      if (this.isJeju === true) {
        window
          .$('.__login, .container,.wrapper.main')
          .css('position', 'inherit')
        window.$('.mobileTopMenu').css('z-index', 'auto')
        window.$('.container').on('scroll touchmove mousewheel', e => {
          e.preventDefault()
        })
      } else {
        window.$('.__login, .container').css('position', 'relative')
        window.$('.wrapper.main').css('position', 'absolute')
        window.$('.mobileTopMenu').css('z-index', '1')
        window.$('.container').unbind()
      }
    },
  },
  async created() {
    await this.updateReadCnt()
    this.$nextTick(() => {
      window.$('img[usemap]').rwdImageMaps()
    })
  },
  methods: {
    JejuDetail() {
      this.isJeju = true
      //   alert(this.isJeju)
    },
    JejuData: function () {
      this.isJeju = false
    },
    async updateReadCnt() {
      try {
        await saveReadCnt({
          eventSeq: this.eventSeq,
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style></style>
